<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-tabs type="card" v-model="activeKey">
          <a-tab-pane key="1" tab="基础/设备信息"></a-tab-pane>
          <a-tab-pane key="2" tab="监测设备信息"></a-tab-pane>
          <a-tab-pane key="3" tab="绑定场景信息"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- ==========================基础信息======================= -->
          <h3 style="font-weight: bold; color: #333;">基础信息</h3>
          <a-form-model-item label="项目ID" prop="monitorpointnum">
            <a-input v-if="(showType=='add' || showType=='edit')&&usertype==0" v-model.trim="formDatas.monitorpointnum" />
            <a-input v-else v-model.trim="formDatas.monitorpointnum" :disabled="true"/>
          </a-form-model-item>
          <a-form-model-item label="项目名称" prop="monitorpointname">
            <a-input v-if="(showType=='add' || showType=='edit')&&usertype==0" v-model.trim="formDatas.monitorpointname" />
            <a-input v-else v-model.trim="formDatas.monitorpointname" :disabled="true"/>
          </a-form-model-item>
          <a-form-model-item label="地址" prop="address">
            <a-input v-if="(showType=='add' || showType=='edit')&&usertype==0" v-model.trim="formDatas.address" />
            <a-input v-else v-model.trim="formDatas.address" :disabled="true"/>
          </a-form-model-item>
          <a-form-model-item label="区域" prop="areacode">
            <a-cascader v-if="(showType=='add' || showType=='edit')&&usertype==0" :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-cascader v-else :disabled="true" :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="areaCascaderSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>
          </a-form-model-item>
          <a-form-model-item label="坐标" prop="coordinatex">
            <a-input v-if="(showType=='add' || showType=='edit')&&usertype==0" v-model.trim="formDatas.coordinatex" style="width: 30%" />
            <a-input v-else v-model.trim="formDatas.coordinatex" style="width: 30%" :disabled="true"/>
            <a-input v-if="(showType=='add' || showType=='edit')&&usertype==0" v-model.trim="formDatas.coordinatey" style="width: 30%; margin-left: 5%" />
            <a-input v-else v-model.trim="formDatas.coordinatey" style="width: 30%; margin-left: 5%" :disabled="true"/>
            <a-button v-if="(showType=='add' || showType=='edit')&&usertype==0" type="primary" style="  width: 30%; margin-left: 5%" @click="selectCoordinateVisible=true">选择</a-button>
          </a-form-model-item>
          <!-- ========================使用单位信息============== -->
          <h3 style="font-weight: bold; color: #333;">使用单位信息</h3>
          <a-form-model-item label="使用单位" prop="userdepid">
            <a-cascader v-if="(showType=='add' || showType=='edit')&&userInfo.usertype==0" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='0'?deptOptionsAll.filter(item => item.deptype=='1'):deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='0'?deptOnLoadChildrenAll:deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-cascader :disabled="true" v-else :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='0'?deptOptionsAll.filter(item => item.deptype=='1'):deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='0'?deptOnLoadChildrenAll:deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
          </a-form-model-item>
<!--          <a-form-model-item label="使用单位代码" prop="depnum">-->
<!--            <span>{{formDatas.depnum}}</span>-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="使用单位地址" prop="dep_address">
            <span>{{formDatas.address}}</span>
<!--            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.address"/>-->
<!--            <a-input :disabled="true" v-else v-model.trim="formDatas.address"/>-->
          </a-form-model-item>
          <a-form-model-item label="邮政编码" prop="mailcode">
            <span>{{formDatas.mailcode}}</span>
<!--            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.mailcode"/>-->
<!--            <a-input :disabled="true" v-else v-model.trim="formDatas.mailcode"/>-->
          </a-form-model-item>
          <a-form-model-item label="值班室电话" prop="dutyroomphone" :max-length="12">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.dutyroomphone"/>
            <a-input :disabled="true" v-else v-model.trim="formDatas.dutyroomphone"/>
          </a-form-model-item>
          <a-form-model-item label="片区负责人姓名" prop="regionmanager">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.regionmanager" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.regionmanager" />
          </a-form-model-item>
          <a-form-model-item label="片区负责人电话" prop="regionmanagerphone">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.regionmanagerphone" :max-length="11" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.regionmanagerphone" :max-length="11" />
          </a-form-model-item>
          <a-form-model-item label="项目负责人姓名" prop="depmanager">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.depmanager" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.depmanager" />
          </a-form-model-item>
          <a-form-model-item label="项目负责人电话" prop="depmanagerphone">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.depmanagerphone" :max-length="11" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.depmanagerphone" :max-length="11" />
          </a-form-model-item>
          <a-form-model-item label="品质负责人姓名" prop="projectmanager">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.projectmanager" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.projectmanager" />
          </a-form-model-item>
          <a-form-model-item label="品质负责人电话" prop="projectmanagerphone">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.projectmanagerphone" :max-length="11" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.projectmanagerphone" :max-length="11" />
          </a-form-model-item>
          <a-form-model-item label="督导负责人姓名" prop="itemsmanager">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.itemsmanager" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.itemsmanager" />
          </a-form-model-item>
          <a-form-model-item label="督导负责人电话" prop="itemsmanagerphone">
            <a-input v-if="(showType=='add' || showType=='edit')&&userdepinfoable==1" v-model.trim="formDatas.itemsmanagerphone" :max-length="11" />
            <a-input :disabled="true" v-else v-model.trim="formDatas.itemsmanagerphone" :max-length="11" />
          </a-form-model-item>
          <!-- ========================监管单位信息============== -->
          <h3 style="font-weight: bold; color: #333;">监管单位信息</h3>
          <a-form-model-item label="监管单位" prop="userdepid">
            <a-cascader v-if="(showType=='add' || showType=='edit')&&userInfo.usertype==0" :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='0'?deptOptionsAll.filter(item => item.deptype=='2'):deptOptions.filter(item => item.deptype=='2')" v-model="regulatoridCascaderSelected" :load-data="userInfo.usertype=='0'?deptOnLoadChildrenAll:deptOnLoadChildren" placeholder="请选择监管单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            <a-cascader :disabled="true" v-else :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='0'?deptOptionsAll.filter(item => item.deptype=='2'):deptOptions.filter(item => item.deptype=='2')" v-model="regulatoridCascaderSelected" :load-data="userInfo.usertype=='0'?deptOnLoadChildrenAll:deptOnLoadChildren" placeholder="请选择监管单位" :change-on-select="true" :allow-clear="false"></a-cascader>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '2'">
          <h3 style="font-weight: bold; color: #333;">监测客户端信息</h3>
          <div style="text-align: right;">
            <a-button v-if="showType=='add' || showType=='edit'&&usertype==0" type="primary" @click="selectmonitorVisible=true">选择</a-button>
          </div>
          <a-table v-if="showMonitorTable" :columns="tableColumns" :data-source="monitorList" row-key="monitorinfoid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
            <span slot="isonline" slot-scope="value">
              <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
            </span>
          </a-table>
          <div style="text-align: right;margin-top: 10px">
            <a-button v-if="showType=='add' || showType=='edit'&&usertype==0" type="primary" @click="selectdeviceVisible=true">选择</a-button>
          </div>
          <a-table v-if="showDeviceTable" :columns="tableColumns1" :data-source="deviceList" row-key="deviceinfoid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
            <span slot="terminaltype" slot-scope="text">
              <span class="text">{{terminaltypesMap[text]||''}}</span>
            </span>
            <span slot="isonline" slot-scope="value">
              <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
            </span>
          </a-table>
        </div>
        <div v-show="activeKey == '3'">
          <h3 style="font-weight: bold; color: #333;">绑定场景信息</h3>
          <div style="text-align: right;">
            <a-button v-if="showType=='add' || showType=='edit'&&usertype==0" type="primary" @click="selectSceneTemplateVisible=true">选择</a-button>
          </div>
          <a-table v-if="showSceneTemplateTable" :columns="tableColumns2" :data-source="sceneList" row-key="scenetemplateid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
            <span slot="scenetype" slot-scope="value">
              <span>{{value?sceneTypes[value-1].dicvalue:''}}</span>
            </span>
          </a-table>
        </div>
      </a-form-model>
    </a-modal>
    <select-coordinate :visible.sync="selectCoordinateVisible" :areacode="formDatas.areacode" @get-coordinate="getCoordinate"></select-coordinate>
    <select-monitor :visible.sync="selectmonitorVisible" :default-selected="monitorList" :formData="formDatas" @get-monitor-selected-rows="getMonitorList"></select-monitor>
    <select-device :visible.sync="selectdeviceVisible" :default-selected="deviceList" :formData="formDatas" @get-selected-rows="getDeviceList"></select-device>
    <select-scene-template :visible.sync="selectSceneTemplateVisible" :default-selected="sceneList" :formData="formDatas" @get-selected-rows="getSceneList"></select-scene-template>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import SelectCoordinate from 'C/modals/SelectCoordinate.vue'
import SelectMonitor from 'C/modals/SelectMonitor.vue'
import SelectDevice from "C/modals/SelectDevice";
import {addMonitorPoint, getMonitorPointinfoById, getMonitorPointPhoneById, modifyMonitorPoint} from "A/monitoring";
import {getCache} from "U/index";
import SelectSceneTemplate from "C/modals/SelectSceneTemplate";
export default {
  mixins: [areaselect, deptselect],
  components: {
    SelectCoordinate,
    SelectMonitor,
    SelectDevice,
    SelectSceneTemplate,
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      autologin:'',
      username:'',
      password:'',
      size: 'default',
      sceneTypes:[],
      phoneLists:[],
      offworkphoneLists:[],
      vehiclephoneLists:[],
      selectCoordinateVisible: false,
      selectmonitorVisible: false,
      selectdeviceVisible:false,
      selectSceneTemplateVisible:false,
      areaCascaderSelected:[],
      userdepidCascaderSelected: [],
      regulatoridCascaderSelected: [],
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      activeKey: '1',
      formDatas: {
        // 基础信息
        monitorpointid: '',
        monitorpointnum: '',
        monitorpointname:'',
        areacode: '',
        address: '',
        coordinatex: '',
        coordinatey: '',
        // 使用单位信息
        userdepid:null,
        regulatorid:null,
        deptype:null,
        dutyroomphone:'',
        depnum:'',
        licensenum:'',
        dep_address:'',
        mailcode:'',
        depmanager:'',
        depmanagerphone:'',
        regionmanager:'',
        regionmanagerphone:'',
        itemsmanager:'',
        itemsmanagerphone:'',
        projectmanager:'',
        projectmanagerphone:'',
        propertyid:'',
        monitorinfos:[],
        deviceinfos:[],
        sceneinfos:[],
        monitorinfoid:'',
        monitornum:'',
        monitorname:'',
        levelid:'',
        linkclient:null,
        handingperson:'',
        handingphone:'',
        offworkphone:'',
        vehiclephone:'',
        onlineorder: '',
        alarmbyvoice: '禁用',
        voiceduration: '120',
        reportsmatter: '禁用',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请输入监控点ID'}],
        monitorpointname: [{required: true, message: '请输入监控点名称'}],
        areacode: [{required: true, message: '请选择区域'}],
        address: [{required: true, message: '请输入监控点地址'}],
        coordinatex: [{required: true, message: '请选择监控点位置坐标'}],
        userdepid: [{required: true, message: '请选择使用单位'}],
      },
      tableColumns: [
        {
          title: '监控软终端名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '监控软终端ID',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      tableColumns1: [
        {
          title: '监控设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '监控设备ID',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      tableColumns2: [
        {
          title: '场景名称',
          dataIndex: 'templatename',
          key: 'templatename',
          ellipsis: true,
        },
        {
          title: '场景ID',
          dataIndex: 'scenetemplateid',
          key: 'scenetemplateid',
          ellipsis: true,
        },
        {
          title: '场景类型',
          dataIndex: 'scenetype',
          key: 'scenetype',
          ellipsis: true,
          scopedSlots: { customRender: 'scenetype' },
        },
      ],
      monitorList:null,
      showDeviceTable: true,
      deviceList:null,
      showMonitorTable:true,
      sceneList:null,
      showSceneTemplateTable:true,
      userdepinfoable:'',
      usertype:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetDatas();
        this.initDetail();
        if(this.detailData){
          this.getMonitorPointPhone();
        }
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    areaCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.areacode = val[val.length-1];
        this.$refs.modalForm.clearValidate('areacode')
      }else {
        this.formDatas.areacode = '';
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.formDatas.userdepid, this.userInfo.usertype!='1');
        if(deptInfo){
          this.formDatas.deptype=deptInfo.deptype
          this.formDatas.levelid=deptInfo.levelid
        }
      }else {
        this.formDatas.userdepid = '';
      }
    },
    regulatoridCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.regulatorid = val[val.length-1];
        this.$refs.modalForm.clearValidate('regulatorid')
      }else {
        this.formDatas.regulatorid = '';
      }
    },
    monitorList(val) {
      if(val && val.length) {
        this.formDatas.monitorid = val.map(item => item.monitorinfoid).join('|');
        this.formDatas.monitorinfoid= val.map(item => item.monitorinfoid).join('|');
      }else {
        this.formDatas.monitorid = '';
        this.formDatas.monitorinfoid='';
      }
    },
    deviceList(val) {
      if(val && val.length) {
        this.formDatas.deviceid = val.map(item => item.deviceinfoid).join('|');
        this.formDatas.deviceinfoid= val.map(item => item.deviceinfoid).join('|');
      }else {
        this.formDatas.deviceid = '';
        this.formDatas.deviceinfoid='';
      }
    },
    sceneList(val) {
      if(val && val.length) {
        this.formDatas.sceneid = val.map(item => item.scenetemplateid).join('|');
        this.formDatas.scenetemplateid= val.map(item => item.scenetemplateid).join('|');
      }else {
        this.formDatas.sceneid = '';
        this.formDatas.scenetemplateid='';
      }
    },
  },
  created() {
    let logininfo=getCache("logininfo",true)
    if(logininfo){
      this.usertype=logininfo.usertype
      this.userdepinfoable = logininfo.userdepinfoable;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.sceneTypes = dictionary.sceneTypes;
    }
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorPointPhone(){
      getMonitorPointPhoneById({monitorpointid: this.detailData.monitorpointid}).then(res=>{
        if (res && res.returncode == '0') {
          this.phoneLists=res.item
        }
      })
    },
    initDetail(){
      if (this.detailData && this.detailData.monitorpointid && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          let params = {
            monitorpointid: this.detailData.monitorpointid
          }
          this.formDatas.onlineorder=this.detailData.onlineorder
          getMonitorPointinfoById(params).then(res => {
            if (res && res.returncode == '0') {
              if(res.item.monitorinfos&&res.item.monitorinfos.length>0){
                this.formDatas.monitorinfoid=res.item.monitorinfos[0].monitorinfoid
                this.formDatas.monitornum=res.item.monitorinfos[0].monitornum
                this.formDatas.monitorname=res.item.monitorinfos[0].monitorname
              }
              if(res.item.deviceinfos&&res.item.deviceinfos.length>0){
                this.formDatas.deviceinfoid=res.item.deviceinfos[0].deviceinfoid
                this.formDatas.devicenum=res.item.deviceinfos[0].devicenum
                this.formDatas.devicename=res.item.deviceinfos[0].devicename
              }
              if(res.item.sceneinfos&&res.item.sceneinfos.length>0){
                this.formDatas.scenetemplateid=res.item.sceneinfos[0].scenetemplateid
                this.formDatas.templatename=res.item.sceneinfos[0].templatename
              }
              for (let key in this.formDatas) {
                if (res.item[key] || res.item[key] === 0) {
                  if(key!="monitorinfoid"||key!="deviceinfoid"){
                    this.formDatas[key] = res.item[key];
                  }
                }
              }
              // 特别数据回显
              if (res.item.areacode) {
                this.initAreaOptionsByAreacode(res.item.areacode)
              }
              if (res.item.userdepid) {
                this.initDeptOptionsByUserdepid(res.item.userdepid, 'userdepidCascaderSelected', this.userInfo.usertype == '0')
                this.initDeptOptionsByUserdepid(res.item.regulatorid, 'regulatoridCascaderSelected', this.userInfo.usertype == '0')
                if (res.item.userdepList) {
                  this.formDatas.deptype=res.item.userdepList.deptype|| ''
                  this.formDatas.depnum = res.item.userdepList.depnum || '';
                  this.formDatas.businessnum = res.item.userdepList.businessnum || '';
                  this.formDatas.depaddress = res.item.userdepList.address || '';
                  this.formDatas.mailcode = res.item.userdepList.mailcode || '';
                  this.formDatas.depmanager=res.item.depmanager||'';
                  this.formDatas.depmanagerphone=res.item.depmanagerphone||'';
                  this.formDatas.regionmanager=res.item.regionmanager||'';
                  this.formDatas.regionmanagerphone=res.item.regionmanagerphone||'';
                  this.formDatas.itemsmanager=res.item.itemsmanager||'';
                  this.formDatas.itemsmanagerphone=res.item.itemsmanagerphone||'';
                  this.formDatas.projectmanager=res.item.projectmanager||'';
                  this.formDatas.projectmanagerphone=res.item.projectmanagerphone||'';
                  this.formDatas.propertyid=res.item.userdepList.propertyid||'';
                }
              }
              if (res.item.monitorid == 0) {
                res.item.monitorid = '';
                this.formDatas.monitorinfoid = '';
              }
              if (res.item.deviceid == 0) {
                res.item.deviceid = '';
                this.formDatas.deviceinfoid = '';
              }
              if (res.item.sceneid == 0) {
                res.item.sceneid = '';
                this.formDatas.scenetemplateid = '';
              }
              if (res.item.monitorinfos && res.item.monitorinfos.length) {
                this.monitorList = res.item.monitorinfos.map(item => {
                  item.monitorinfoid = '' + item.monitorinfoid;
                  return item;
                });
              }
              if (res.item.deviceinfos && res.item.deviceinfos.length) {
                this.deviceList = res.item.deviceinfos.map(item => {
                  item.deviceinfoid = '' + item.deviceinfoid;
                  return item;
                });
              }
              if (res.item.sceneinfos && res.item.sceneinfos.length) {
                this.sceneList = res.item.sceneinfos.map(item => {
                  item.scenetemplateid = '' + item.scenetemplateid;
                  return item;
                });
              }
            }
          })
        })
      }
    },
    confirm() {
      if (this.formDatas.monitorinfoid == null || this.formDatas.monitorinfoid == '') {
        this.formDatas.linkclient = 0
      } else {
        this.formDatas.linkclient = 1
      }
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formDatas,
              offworkphone: this.formDatas.offworkphone.toString(),
              vehiclephone: this.formDatas.vehiclephone.toString(),
              monitorinfoid: this.formDatas.monitorinfoid == '' ? '0' : this.formDatas.monitorinfoid,
            };
            if (this.showType == 'add'&&this.formDatas.brandid!='') {
              this.showLoading();
              addMonitorPoint(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.formDatas.brandid!=''){
              this.showLoading();
              modifyMonitorPoint(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      }
    },
    getCoordinate(coordinate) {
      this.formDatas.coordinatex = coordinate[0];
      this.formDatas.coordinatey = coordinate[1];
    },
    getDeviceList(rows) {
      this.deviceList = rows;
      this.formDatas.deviceinfos=[]
      for(let i=0;i<rows.length;i++){
        this.formDatas.deviceinfos.push({
          deviceinfoid:rows[i].deviceinfoid,
          devicenum:rows[i].devicenum,
          devicename:rows[i].devicename,
        })
      }
      // if(this.deviceList.length>0){
      //   this.getParamsConfig(this.deviceList[0].devicenum)
      // }
    },
    getMonitorList(rows) {
      this.monitorList = rows;
      this.formDatas.monitorinfos=[]
      for(let i=0;i<rows.length;i++){
        this.formDatas.monitorinfos.push({
          monitorinfoid:rows[i].monitorinfoid,
          monitornum:rows[i].monitornum,
          monitorname:rows[i].monitorname,
        })
      }
    },
    getSceneList(rows) {
      this.sceneList = rows;
      this.formDatas.sceneinfos=[]
      for(let i=0;i<rows.length;i++){
        this.formDatas.sceneinfos.push({
          scenetemplateid:rows[i].scenetemplateid,
          templatename:rows[i].templatename,
        })
      }
    },
    resetDatas() {
      this.formDatas.monitorpointid = '';
      this.userdepidCascaderSelected = [];
      this.monitorList = null;
      this.deviceList = null;
      this.sceneList = null;
      // if (this.$refs.modalForm) {
      //   this.$refs.modalForm.clearValidate();
      // }
      if(this.$refs.modalForm){
        this.$refs.modalForm.resetFields();
      }
      this.formDatas.coordinatey=null;
      this.areaCascaderSelected=[];
    },
  }
}
</script>