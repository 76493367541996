<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" v-if="userInfo.rolename!='项目调度员'&&userInfo.rolename!='项目管理员'">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" style="width: 25%">
              <a-input v-model.trim="queryParams.address" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="width: 20%;float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="项目ID" prop="monitorpointnum" style="width: 25%">
              <a-input v-model.trim="queryParams.monitorpointnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="区域" prop="areacode" style="width: 25%">
              <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname" style="width: 25%">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否绑定终端" prop="linkclient" style="width: 25%">
              <a-select v-model.trim="queryParams.linkclient" placeholder="请输入">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
                <a-select-option :value="2">全部</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <template>-->
<!--              <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>-->
<!--            </template>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="monitorpointid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="onlineorder" slot-scope="value">
            <a-tag :color="value==4?'green':value==0?'red':value==1?'':''">{{value==4?'在线':value==0?'离线':value==1?'无设备':value}}</a-tag>
          </span>
          <span slot="monitoring" slot-scope="value, record">
            <a-button type="primary" size="small" @click="operationClick({key:'monitoring-'+record.monitorpointid})">实时监控</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.monitorpointid">详情</a-menu-item>
                <a-menu-item v-if="userInfo.rolename!='项目调度员'" :key="'edit-'+record.monitorpointid">修改</a-menu-item>
                <a-menu-item v-if="userInfo.usertype==0" :key="'delete-'+record.monitorpointid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import {getCache, getItemFromArrayByKey, getTimeRange} from 'U'
import store from '@/store'
import {mapGetters, mapState} from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import addOrEditModal from './addOrEditModal'
import {
  addPointParams,
  deleteMonitorPoint,
  getMonitorPointinfoById,
  getMonitorPointListByCondition,
  getPointParamsListByCondition, modifyPointParams
} from "A/monitoring";
import specialEventModal from "V/businessManagement/reportForms/specialEvent/addOrEditModal.vue";
import SelectSpecialEvent from "C/modals/SelectSpecialEvent.vue";
import SelectRepairsAndMatters from "C/modals/SelectRepairsAndMatters.vue";
export default {
  name: 'monitorfile',
  mixins: [areaselect, deptselect, pagination],
  components: {
    SelectRepairsAndMatters, SelectSpecialEvent, specialEventModal,
    addOrEditModal,
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        monitorpointnum: '',
        address: '',
        areacode: '',
        monitornum:'',
        userdepname:'',
        devicenum:'',
        userdepid:'',
        userdepsid:'',
        linkclient: 1,
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '项目ID',
          dataIndex: 'monitorpointnum',
          key: 'monitorpointnum',
          ellipsis: true,
          width: 165
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '使用单位',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '在线/离线',
          dataIndex: 'onlineorder',
          key: 'onlineorder',
          ellipsis: true,
          width: 80,
          scopedSlots: {customRender: 'onlineorder'}
        },
        {
          title: '',
          key: 'monitoring',
          width: 100,
          scopedSlots: { customRender: 'monitoring' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      deleteenable:'',

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return '项目档案';
    },
    breadcrumb() {
      return [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
  },
  methods: {
    init() {
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.deleteenable = logininfo.deleteenable;
      }
      this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
      for(let i=0;i<this.menu.children.length;i++){
        this.btnList.push(this.menu.children[i].menuname)
      }
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMonitorPointListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.monitorpointid);
      }).catch(()=>{
      });
    },
    delete(monitorpointid) {
      this.showLoading();
      if(monitorpointid) {
        let params = {
          monitorpointid
        };
        deleteMonitorPoint(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      if(type == 'detail') {
        this.modalShowType = type;
        this.modalDetailData = record;
        this.modalVisible = true;
      }else {
        this.modalShowType = type;
        if(type == 'add') {
          this.modalDetailData = null;
        }else {
          this.modalDetailData = record;
        }
        this.modalVisible = true;
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let monitorpointid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'monitorpointid', monitorpointid);
      if(type == 'detail') {
        this.showModal('detail', monitorpointid, record)
      }else if(type == 'monitoring') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        let params = {
          monitorpointid: record.monitorpointid
        };
        this.showLoading();
        getMonitorPointinfoById(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            if((res.item.deviceinfos || res.item.deviceinfos.length)||(res.item.monitorinfos || res.item.monitorinfos.length)) {
              this.$router.push({
                name: 'timeMonitoring',
                params: {
                  monitorpointid: record.monitorpointid,
                  monitorpointnum: res.item.monitorpointnum,
                  userdepid:res.item.userdepid,
                  deviceinfos: res.item.deviceinfos,
                  sceneinfos: res.item.sceneinfos,
                  terminaltype: res.item.deviceinfos.length>0?res.item.deviceinfos[0].terminaltype:'',
                  monitorinfos:res.item.monitorinfos,
                  prePages: prePages
                }
              })
            }else {
              this.$message.error('该监控点无监控设备');
            }
          }
        })
      }else if(type == 'edit') {
        this.showModal('edit', monitorpointid, record)
      }else if(type == 'delete') {
        this.deleteConfirm(monitorpointid, record)
      }else {
        this.operationLiftid = monitorpointid;
        this[type+'ModalVisible'] = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>